import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Form, Button, Dropdown } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
import Dashboardheader from "../directives/dashboardheader";
import "../component/packageplan.css";
import {
  DisplayAllSubscriptionPlanForUser,
  DisplaySubscription,
} from "../Action/user.action";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import Dashboardsubheader from "../directives/dashboardsubheader";
import PackagePlanMetaTag from "./MetaTags/PackagePlanMetaTag";
import toast from "react-hot-toast";

const Packageplan = () => {
  const [getplan, setplan] = useState([]);
  const navigate = useNavigate();

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
        Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
      );

  useEffect(() => {
    getPackagePlan();
  }, []);

  const redirection = () => {
    navigate(`${config.baseUrl}dashboard#subscribedplan`);
  };

  const redirection2 = (data) => {
    if(loginData && loginData.authToken){
      navigate(`${config.baseUrl}nftdetail/${data}`);
    }else{
      toast.error('Access denied: Please log in to proceed.')
      // navigate(`${config.baseUrl}wallethistory`);

    }
  };

  useEffect(() => {
    console.log(getplan);
  }, [getplan]);

  const getPackagePlan = async () => {
    if (loginData.id) {
      let res = await DisplayAllSubscriptionPlanForUser();

      if (res.success) {
        setplan(res.data);
      } else {
        setplan([]);
      }
    } else {
      let res = await DisplaySubscription();
      if (res.success) {
        setplan(res.data);
      } else {
        setplan([]);
      }
    }
  };

  console.log(getplan,"=====")
  return (
    <>
      {loginData.id ? (
        <>
          <Dashboardsubheader /> <Dashboardheader />
        </>
      ) : (
        <Header />
      )}
      {/* <Dashboardheader /> */}
     <PackagePlanMetaTag />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden position-relative overflow-hidden mt-5 pt-2 mb-5">
          {/* Basic NFT */}
          <div>
            <Container className="">
              <div>
                <h2 className="text-white" style={{ fontWeight: 600 }}>
                  NFTs{" "}
                </h2>
              </div>
              <br />
              <Row>
                {getplan
                  .filter((item) => [1, 2].includes(item.editionTypeId
                  ))
                  .map((item, index) => (
                    <Col lg={4} md={6} key={index} className="mb-4">
                      <div className="card nftplan">
                        <div className="card-body px-3 pt-1">
                         

                          <div className="pt-3 pb-1 px-2">
                            <h5 className="mb-0 text-white ">{item.name}</h5>

                            <div className="d-flex gap-2 justify-content-between mb-3 mt-2">
                            <div>
                            <p  style={{fontSize:14}} className="mb-0 px-3 py-1 w-auto">${item.price}</p>
                          </div>
                              <div>
                                {item.purchased == 1 ? (
                                  <Button
                                    variant="main2"
                                    onClick={redirection}
                                    className="rounded-1 btn-sm mh-auto px-4 " 
                                    style={{fontSize:14}}
                                  >
                                    Subscribed
                                  </Button>
                                ) : (
                                  <Button
                                    variant="main2"
                                 
                                    onClick={() => redirection2(item.id)}
                                    className="rounded-1 btn-sm mh-auto px-4 " 
                                    style={{fontSize:14}}
                                  >
                                    Buy Now
                                  </Button>
                                )}
                              </div>
                            
                            </div>
                          </div>
                          <div className="card-view text-center p-2 rounded-30">
                            <img
                              src={`${config.ipfsurl + item.image}`}
                              alt={item.image}
                            />
                          </div>

                        
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Container>
          </div>

          {/* Limited edition */}
          {/* <div>
            <Container className="">
              <div className=" mt-5">
                <h2 className="text-white" style={{ fontWeight: 600 }}>
                  Limited Edition{" "}
                </h2>
              </div>

              <Row>
                {getplan
                  .filter(
                    (item) => item.editiontypeid == 2 || item.editionTypeId == 2
                  )
                  .map((item, index) => (
                    <Col lg={4} style={{ marginTop: "30px", display: "flex" }}>
                      <div className="card nftplan flex-fill">
                        <div className="card-body p-3  d-flex justify-content-between flex-column">
                      
                        <div className="pt-3 pb-1 px-2">
                            <h5 className="mb-0 text-white ">{item.name}</h5>

                            <div className="d-flex gap-2 justify-content-between mb-3 mt-2">
                            <div>
                            <p  style={{fontSize:14}} className="mb-0 px-3 py-1 w-auto">${item.price}</p>
                          </div>
                              <div>
                                {item.purchased == 1 ? (
                                  <Button
                                    variant="main2"
                                    onClick={redirection}
                                    className="rounded-1 btn-sm mh-auto px-4 " 
                                    style={{fontSize:14}}
                                  >
                                    Subscribed
                                  </Button>
                                ) : (
                                  <Button
                                    variant="main2"
                                 
                                    onClick={() => redirection2(item.id)}
                                    className="rounded-1 btn-sm mh-auto px-4 " 
                                    style={{fontSize:14}}
                                  >
                                    Buy Now
                                  </Button>
                                )}
                              </div>
                             
                            </div>
                          </div>
                          <div className="card-view text-center p-4 rounded-30">
                            <img
                              src={`${config.ipfsurl + item.image}`}
                              alt={item.image}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Container>
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
};
export default Packageplan;
