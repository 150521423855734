import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Tab,
  Nav,
  Modal,
} from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "../component/home.css";
import { Helmet } from "react-helmet";
import { getcmsAction } from "../Action/user.action";
import Wfooter from "../directives/Wfooter";

const AlphaPlanTermsAndConditions = () => {
  const [termsandconditionlist, settermsandconditionlist] = useState({
    planterms:""
  });
  useEffect(() => {
    getTermsAndConditionFunc();
    // setcondition();
  }, []);

  const getTermsAndConditionFunc = async () => {
    let res = await getcmsAction("alphatnc");
    if (res.success) {
      settermsandconditionlist(res.data);
      console.log(res.data);
    } else {
    }
  };

  return (
    <>
      <div className="lighttheme overflow-hidden hero" style={{ minHeight: "100vh" }} >
        <section className="herobanner position-relative text-white overflow-hidden" style={{backgroundColor:"black"}}>
          <Container>
            <Row className="align-items-center">
              <div className="jovitClass"
                dangerouslySetInnerHTML={{
                  __html: termsandconditionlist.alphatnc,
                }}
              />
            </Row>
          </Container>
        </section>
      </div>

      
    </>
  );
};
export default AlphaPlanTermsAndConditions;
