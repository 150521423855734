import React, { useEffect, useState } from "react";
import config from "../config/config";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import { TextField } from "@mui/material";
import Cookies from "js-cookie";
import {
  WithdrawalHistoryAction,
  addWithdrawRequestAction,
  addmainwalletwithdrawrequestAction,
} from "../Action/user.action";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";

const Withdraw = () => {
  const [mntAmount, setMntAmount] = useState();
  const [userDetails, setuserDetails] = useState({});
  const [validationError, setvalidationError] = useState({});
  const [withdrawHistorylist, setWithdrawHistorylist] = useState([]);
  const headingRef = React.useRef(null);
  const [balanceType, setbalanceType] = useState(1);
  useEffect(() => {
    withdrawHistory();
  }, []);

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = ({ selected }) => {
    // alert(1);
    setTimeout(() => {
      headingRef.current.scrollIntoView();
    }, 500);

    console.log(selected, "selected123");
    setCurrentPage(selected);
  };

  const heading = () => {
    console.log(headingRef.current.scrollIntoView());

    console.log(document.getElementById("withdrawHistorylistHeading"));
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  const totalPages = Math.ceil(withdrawHistorylist.length / itemsPerPage);
  const isOnFirstPage = currentPage == 0;
  const isOnLastPage = currentPage == totalPages - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const withdrawHistorylistSliced = withdrawHistorylist.slice(
    startIndex,
    endIndex
  );

  const withdrawHistory = async () => {
    let res = await WithdrawalHistoryAction();
    if (res.success) {
      setuserDetails(res.data.statistics[0]);
      setWithdrawHistorylist(res.data.History);
    } else {
      // seterrormsg(res.msg)
      setuserDetails({});
    }
  };

  const inputhandlerbalancetype = (e) => {
    let { name, value, id } = e.target;
    if (value == 1) {
      setbalanceType(1);
    } else {
      setbalanceType(2);
    }
  };

  const inputHandler = (e) => {
    let { name, value, id } = e.target;

    var getDecimalVal = value.toString().indexOf(".");
    var decimalPart = value.toString().substring(getDecimalVal + 1);
    if (decimalPart.length > 2 && getDecimalVal != "-1") {
    } else {
      setMntAmount(value);
    }
  };

  function validate() {
    let withdrawAmountError = "";

    if (
      parseFloat(mntAmount).toFixed(2) === "" ||
      parseFloat(mntAmount).toFixed(2) == "0.00"
    ) {
      withdrawAmountError = "Amount is required.";
    } else if (!isNaN(parseFloat(mntAmount).toFixed(2)) === false) {
      withdrawAmountError = "Amount is not valid please enter valid amount.";
    } else if (
      parseFloat(parseFloat(mntAmount).toFixed(2)) <
      parseFloat(
        parseFloat(userDetails?.mining_reward_min__mnt_withdraw).toFixed(2)
      )
    ) {
      withdrawAmountError = `Amount should be greater than or equal to ${parseFloat(
        userDetails?.mining_reward_min__mnt_withdraw
      ).toFixed(2)} MNT`;
    } else if (userDetails.mnt_withdraw_permitted != 1) {
      withdrawAmountError =
        "Withdraw Service Temporarily Unavailable. Please try again later.";
    }
    if (balanceType == 1) {
      if (
        parseFloat(parseFloat(mntAmount).toFixed(2)) >
        parseFloat(parseFloat(userDetails?.step_balance).toFixed(2))
      ) {
        withdrawAmountError = "Insufficient funds in your Stepmint Wallet.";
      }
    }
    if (balanceType == 2) {
      if (
        parseFloat(parseFloat(mntAmount).toFixed(2)) >
        parseFloat(parseFloat(userDetails?.main_balance).toFixed(2))
      ) {
        withdrawAmountError = "Insufficient funds in your Main Wallet.";
      }
    }

    if (withdrawAmountError) {
      setvalidationError({
        withdrawAmountError,
      });
      return false;
    } else {
      return true;
    }
  }

  const withdrawFunc = async () => {
    const isValid = validate();
    if (isValid) {
      setvalidationError({});
      Swal.fire({
        title: `Amount Requested : ${parseFloat(
          parseFloat(mntAmount).toFixed(2)
        )} MNT`,
        text: `Are you ready to Withdraw the Amount of ${
          balanceType == 1 ? "Stepmint Wallet" : "Main Wallet"
        }?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Withdraw it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res;
          if (balanceType == 1) {
            res = await addWithdrawRequestAction({
              usd_amount: parseFloat(parseFloat(mntAmount).toFixed(2)),
              unhold:true
            });
          } else {
            res = await addmainwalletwithdrawrequestAction({
              usd_amount: parseFloat(parseFloat(mntAmount).toFixed(2)),
              unhold:true
            });
          }
          if (res.success) {
            Swal.fire("Amount Withdrawn!", res.msg, "success");
            setTimeout(() => {
              window.location.href = `${config.baseUrl}wallethistory`;
            }, 1500);
          } else {
            Swal.fire("Failed to Withdraw!", res.msg, "error");
          }
        }
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  const maxToken = async () => {
    if (balanceType == 1) {
      setMntAmount(userDetails.step_balance);
    } else {
      setMntAmount(userDetails.main_balance);
    }
  };

  const loginData = !Cookies.get("loginSuccessStepMrMint")
    ? []
    : JSON.parse(Cookies.get("loginSuccessStepMrMint"));

  return (
    <>
      <Dashboardheader />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          {/* ---------- */}
          <div className=" mb-5">
            <Container className="">
              <div className=" card card-view rounded-20 border-0">
                <div className="card-body">
                  <Row className="align-items-center">
                    <Col lg={6} className="">
                      <div className="withdraw_form">
                        <div className="text-center mb-2 pb-1">
                          <h3>Withdraw</h3>
                          <h6 className="mb-4 text-grey">
                            Get your balance in your registered Wallet
                          </h6>
                        </div>
                        <Col lg={12} className="mb-4">
                          <TextField
                            id="outlined-helperText"
                            label="Withdraw Address"
                            type="text"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="0x0E276DF710AD6dD91A3"
                            autoComplete="off"
                            value={loginData?.bnb_address}
                          />
                        </Col>
                     
                          <div className="mxbtn position-relative">
                            <div>
                            <Row>
                                
                                <Col lg={6}>
                                 
                                </Col>
                                <Col lg={6}>
                                  <div class="pull-right mntbalance mb-0">
                                    Balance :&nbsp;
                                    <span id="web13Balance">
                                      {balanceType == 1
                                        ? parseFloat(
                                            userDetails.step_balance
                                          ).toFixed(2)
                                        : parseFloat(
                                            userDetails.main_balance
                                          ).toFixed(2)}{" "}
                                      MNT
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <TextField
                                id="outlined-password-input"
                                label="Amount"
                                type="text"
                                style={{ width: "100%" }}
                                autoComplete="off"
                                placeholder="Enter Amount"
                                // name="mntAmount"
                                value={mntAmount}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onKeyPress={(event) => {
                                  if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onPaste={handleChange}
                                onChange={inputHandler}
                              />
                            </div>

                            <button
                              onClick={maxToken}
                              className="pull-right text-white px-4 btn btn-main2 maxamount rounded-2"
                              type="button"
                            >
                              MAX
                            </button>
                          </div>
                          {/* <br/> */}
                          <div style={{ marginTop: 8 }}>
                            <span
                              className="validationErr "
                              style={{ color: "red", marginTop: "5px",marginBottom:5, fontSize:14 }}
                            >
                              {validationError.withdrawAmountError ? (
                                validationError.withdrawAmountError
                              ) : (
                                <span style={{ visibility: "hidden" }}> .</span>
                              )}
                            </span>
                          </div>
                     
                        <div className="mb-4 mt-2">
                          <h6 className="mb-1">
                            <small className="text-light-grey">
                              Withdraw Fee 4%{" "}
                            </small>
                          </h6>
                          <h6 className="mb-1">
                            <small className="text-light-grey">
                              Burning Fee 1%{" "}
                            </small>
                          </h6>
                          <h6>
                            <small className="text-light-grey">
                              You will get :{" "}
                              {isNaN(
                                parseFloat(mntAmount) -
                                  parseFloat(mntAmount) *
                                    (parseFloat(
                                      userDetails.mnt_withdrawal_fee_percent
                                    ).toFixed(2) /
                                      100)
                              )
                                ? 0
                                : parseFloat(
                                    parseFloat(mntAmount) -
                                      parseFloat(mntAmount) *
                                        (parseFloat(
                                          userDetails.mnt_withdrawal_fee_percent
                                        ).toFixed(2) /
                                          100)
                                  ).toFixed(2)}
                              &nbsp;MNT
                            </small>
                          </h6>
                        </div>
                        {console.log(
                          isNaN(
                            parseFloat(mntAmount) -
                              parseFloat(mntAmount) *
                                (parseFloat(
                                  userDetails.mnt_withdrawal_fee_percent
                                ).toFixed(2) /
                                  100)
                          )
                        )}
                        <Button
                          variant="main2"
                          className="w-100 mb-3"
                          onClick={withdrawFunc}
                        >
                          Withdraw
                        </Button>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginTop: "2.5px",
                          }}
                        >
                          Note: Withdrawal requests will be approved at 12 PM
                          and 7 PM Everyday.
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginTop: "2.5px",
                          }}
                        >
                         {userDetails?.free_signup ? 'Daily Withdrawal Limit: 20 MNT' : 'Daily Withdrawal Limit: 1500 MNT'} 
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-3">
                      <div>
                        <img
                          src="assets/images/withdraw.png"
                          width="100%"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
          {/* ---------- */}
          <div className="mb-2">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h1
                   className="text-white"
                    ref={headingRef}
                    onClick={heading}
                    id="#withdrawHistorylistHeading"
                  >
                    Withdraw History
                  </h1>
                </Col>
              </Row>
            </Container>
          </div>

          {/* ---------- */}

          <div className="latest_transaction mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="">
                    <div className="table-responsive">
                      <table width="100%" className="text-center">
                        <thead>
                          <tr className="table-head px-4">
                            <th>S.No</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Approve Date</th>
                          </tr>
                        </thead>
                        <tbody className="card-view1 p-4">
                          {withdrawHistorylist.length == 0 ? (
                            <tr className="tr_list">
                              <td colspan="7">No Data Available</td>
                            </tr>
                          ) : (
                            withdrawHistorylistSliced.map((item, index) => (
                              <tr className="tr_list">
                                <td style={{width:"100px"}}>{index + currentPage * itemsPerPage + 1}</td>
                                <td>{item.usd_amount}&nbsp;MNT</td>
                                <td>{item.withdraw_type}</td>
                                <td
                                  style={{
                                    color: `${
                                      item.status == "Rejected"
                                        ? "Red"
                                        : item.status == "Approved"
                                        ? "Green"
                                        : "#D3D3D3"
                                    }`,
                                  }}
                                >
                                  {item.status}
                                </td>
                                <td>{item.requestDate}</td>
                                <td>
                                  {item.status == "Rejected"
                                    ? ""
                                    : item.approvedDate}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      {withdrawHistorylist.length > itemsPerPage && (
                        <div className="pagination-container">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            previousClassName={
                              isOnFirstPage ? "btn  disabled" : ""
                            }
                            nextClassName={isOnLastPage ? "btn  disabled" : ""}
                            previousLinkClassName={
                              isOnFirstPage ? "disabled-link" : ""
                            }
                            nextLinkClassName={
                              isOnLastPage ? "disabled-link" : ""
                            }
                            breakLabel={"..."}
                            pageCount={Math.ceil(
                              withdrawHistorylist.length / itemsPerPage
                            )}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      )}
                    </div>

                    {/* <div className="text-right mt-4">
                      <Button variant="custom" className="text-white px-5">
                        See all
                      </Button>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default Withdraw;
