import React, { useContext, useEffect, useState } from "react";
import { UserProfile } from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import {
  Container,
  Nav,
  Navbar,
  Offcanvas,
  Button,
} from "react-bootstrap";
import Cookies from "js-cookie";

import CopyToClipboard from "react-copy-to-clipboard";
import config from "../config/config";
import { Link } from "react-router-dom";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@material-ui/core";
import Notification from "../component/Notification/Notification";
export default function Dashboardsubheader() {
  const [userdata, setuserdata] = useState([]);
  const [viewbalance, setviewbalance] = useState(false);

  useEffect(() => {


    getStepMintProfile();
  }, []);

  const handleViewBalance = () => {
    setviewbalance(!viewbalance);
  };

  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const getStepMintProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setuserdata(res.data);
    } else {
      setuserdata([]);
    }
  };

  const logout = async () => {
    Cookies.remove("loginSuccessStepMrMint");
    Cookies.remove("loginSuccessMrMint", { domain: "mrmint.io" });
    window.localStorage.clear();
    setTimeout(() => {
      window.location.href = `${config.baseUrl}`;
    });
  };

  const truncateText = (text, maxLength = 16) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  const firstName = userdata.first_name == null ? "User" : userdata.first_name;
  const lastName = userdata.last_name == null ? "" : userdata.last_name;

  const fullName = `${firstName} ${lastName}`.trim();

  return (
    <>
      <header className="dashboardheader pt-2 pb-2">
        <Container>
          <Toaster />
          {["xl"].map((expand) => (
            <Navbar key={expand} expand={expand} className="">
              <Container fluid className="pl-0 pr-0">
                <Navbar.Brand as={Link} to={`${config.baseUrl}`}>
                  <img src="assets/images/f-logo.png" />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  className="mobile_header"
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <img src="assets/images/f-logo.png" width="180px" />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className=" flex-grow-1 mx-1 ">
                      <div className="">
                        <div className="total_amount ">
                          <div>
                            <ul className="d-flex flex-lg-row flex-column align-items-start align-items-lg-center justify-content-center  py-2 px-4">
                              <li className="me-3 subhead-block1">
                                <span className="mb-0" style={{ paddingBottom: 5 }}>
                                  Balance
                                </span>
                              </li>
                              <li >
                                <div className=" subhead-block2 d-flex align-items-center flex-wrap">

                                  <span className="me-4 d-flex gap-2 " >
                                    <img
                                      src="assets/images/mntcoin.png"
                                      width="20px"
                                      height="20px"
                                    />
                                    <p style={{ width: 150 }} className="mb-0">{viewbalance ? userdata.step_balance : '********'} MNT</p>
                                  </span>

                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={viewbalance}
                                        onChange={handleViewBalance}
                                      />
                                    }
                                    label={<p className="mb-0" onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }} style={{ fontSize: 10, width: 30 }}>{viewbalance ? "Hide" : "Show"}</p>}
                                    style={{ color: "white" }}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Nav>
                    <Notification/>
                    <Nav className="me-3">
                      <Link to={`${config.baseUrl}profile`} className="profile_box px-3 lg-mt-3 text-decoration-none">
                        <div className="p-img">
                          <a >
                            {userdata.profile_pic == null ||
                              userdata.profile_pic == "" ? (
                              <img
                                src={`${config.imageUrl}/defaultProfile.png`}
                                alt="userprofile"
                              />
                            ) : (
                              <img
                                src={`${config.imageUrl}${userdata.profile_pic}`}
                                alt="userprofile"
                              />
                            )}
                          </a>
                        </div>


                      </Link>
                      <div className="text-start">
                        <p className="text-white text-capitalize text-wrap text-break mb-0" style={{ maxWidth: "100px" }}>
                          {truncateText(firstName + " " + lastName)}
                        </p>

                        {userdata.bnb_address == undefined ? "" :
                          <span className="text-gradient">
                            <b>
                              {" "}
                              <span class="text-sm text-bold text-light">
                                {userdata.bnb_address?.slice(0, 4) +
                                  "...." +
                                  userdata.bnb_address?.slice(-4)}{" "}
                              </span>
                              <CopyToClipboard text={userdata.bnb_address}>
                                <span
                                  title="Click to Copy"
                                  className="mr-copylink"
                                  id="token-buy-button"
                                  onClick={(event) => {
                                    copieBtn(); // Call your existing click handler
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "rgb(157 81 255)",
                                  }}
                                >
                                  <i class="fa fa-clone "></i>
                                </span>
                              </CopyToClipboard>
                            </b>
                          </span>
                        }
                      </div>
                    </Nav>
                    <Nav>

                      <div className="mt-1">
                        <Button
                          className="btn btn-main2 text-white rounded-2 cust-logout"
                          onClick={logout}
                        >
                          Logout
                        </Button>
                      </div>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </Container>
      </header>
    </>
  );
}