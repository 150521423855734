import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config";
import { getAllNotificationAction, getAllNotificationCountAction, UpdateNotificationStatusAction } from "../../Action/user.action";
import { useNotification } from "./NotificationContext";

const Notification = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [notificationData, setNotificationData] = useState([])
  // const [notificationCount, setNotificationCount] = useState([])
  const [notificationReadStatus, setNotificationReadStatus] = useState([])
  const { notificationCount, updateNotificationCount } = useNotification();

  console.log(notificationData);
  const navigate = useNavigate();
  const handleViewMoreClick = () => {
    setIsHovered(false); // Reset hover state
  };

  useEffect(() => {
    getNotificationForUser()
    updateNotificationCount()
  }, [])
  // -------------------------------------------Api Calling for Geting All Notification-------------------
  const getNotificationForUser = async () => {
    try {
      const data = {
        "limit": 2,
        "page": 0
      }
      const res = await getAllNotificationAction(data)
      if (res.success) {
        setNotificationData(res.data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  // --------------------------------Api For Update Notification Status ----------------------
  const UpdateNotificationCount = async () => {
    navigate(`${config.baseUrl}notifications`)
    setIsHovered(false)
  }

  return (
    <div
      className="notification-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="bell-icon">
        <img src="assets/images/noti.svg" style={{width:25, height:25}} alt="" />
       {notificationCount > 0 && <span className="ring-animation">{ notificationCount}</span>}
      </div>

      {(isHovered && notificationCount > 0)&& (
        <div className="bg-black" style={{ backgroundColor: "black" }}>
          <div className="notification-dropdown">
            <ul>
              <strong className="px-2 mt-2">New Notifications</strong>
              {notificationData.map((notification, index) => {
                const isUnread = notification?.users?.[0]?.read_date == null;
                return (
                  <>
                  
                    <li key={index} onClick={UpdateNotificationCount} className={`align-items-center py-3 mb-1  px-3 w-100 position-relative ${isUnread ? 'notification-row' : ''}`}>
<div className="d-flex gap-2 align-items-center">
<div style={{  borderRadius:"50%",}}>
                      <img style={{width:30, height:30,}} className="img-fluid" src="assets/images/stepmint-icon.png" />
                      </div>

                      <div className="" style={{width:"90%"}}>
                      <p
                        className="mb-0 "
                        style={{
                          fontSize: 13,
                          wordBreak: "break-word",
                          fontWeight: "bold",
                          color: "#212121",
                          textTransform: "capitalize",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          width:200
                        }}
                      >
                        {notification.notifi_title}
                      </p>
                      <p
                        className="mb-0  "
                        style={{
                          fontSize: 11,
                          wordBreak: "break-word",
                          fontWeight: "semibold",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          color: "#00000070",
                          textTransform:"capitalize",
                          width:200

                        }}
                      >
                        {notification.notifi_description}
                      </p>
                      </div>
</div>
                    
                    </li>
                  </>
                )

              })}
              <div className="d-flex notification-row justify-content-center mt-2">
                <Link
                  to={`${config.baseUrl}notifications`}
                  style={{ textDecoration: "none" }}
                  onClick={handleViewMoreClick}
                >
                  <p
                    className="mb-0 text-black py-2"
                    style={{
                      fontSize: 12,
                      wordBreak: "break-word",
                      fontWeight: "semibold",
                    }}
                  >
                    View more +
                  </p>
                </Link>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};


export default Notification;