// Create a new context file
import React, { createContext, useContext, useState } from 'react';
import { getAllNotificationCountAction } from '../../Action/user.action';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  const updateNotificationCount = async () => {
    try {
      const res = await getAllNotificationCountAction();
      if (res.success) {
        setNotificationCount(res.data.count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NotificationContext.Provider value={{ notificationCount, updateNotificationCount }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);