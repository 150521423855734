import React, { useEffect, useState } from "react";
import config from "../config/config";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import {
  ReferralUserList,
  ShowReferralHistory,
  UserProfile,
} from "../Action/user.action";
import CopyToClipboard from "react-copy-to-clipboard";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";

const Referral = () => {
  const [getreferral, setreferral] = useState([]);
  const [getuserreferral, setuserreferral] = useState([]);

  const [getreferralcount, setreferralcount] = useState("");
  const [gettotalamountusdt, settotalamountusdt] = useState("");

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const [userdata, setuserdata] = useState([]);
  const handlePageChange = ({ selected }) => {
    console.log(selected, "selected");
    setCurrentPage(selected);
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  const totalPages = Math.ceil(getreferral.length / itemsPerPage);
  const totalPagesuserreferral = Math.ceil(
    getuserreferral.length / itemsPerPage
  );

  const isOnFirstPage = currentPage == 0;
  const isOnLastPage = currentPage == totalPages - 1;
  const isOnLastPageuserreferral = currentPage == totalPagesuserreferral - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const getreferralSliced = getreferral.slice(startIndex, endIndex);
  const getuserreferralSlice = getuserreferral.slice(startIndex, endIndex);

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );

  useEffect(() => {
    ReferralFunc();
    getStepMintProfile();
  }, []);

  const copieBtn = async () => {
    toast.success(`Copied!`);
  };

  const getStepMintProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setuserdata(res.data);
    } else {
      setuserdata([]);
    }
  };

  const ReferralFunc = async () => {
    let res = await ShowReferralHistory(1);
    let res1 = await ReferralUserList();
    if (res.success) {
      setreferral(res.data.history);
      setreferralcount(res.data.statistics.referral);
      //   setreferralcount(res.data.length)
      //   let totalamount = 0;
      //   for (let i = 0; i < res.data.length; i++) {
      //     totalamount += parseFloat(res.data[i].amount);
      //   }
      //   settotalamountmnt(totalamount);
      let totalamountUSDT = 0;
      for (let i = 0; i < res.data.history.length; i++) {
        totalamountUSDT += parseFloat(res.data.history[i].usdAmount);
      }
      settotalamountusdt(totalamountUSDT);
    } else {
      setreferral([]);
    }
    if (res1.success) {
      setuserreferral(res1.data);
    } else {
      setuserreferral([]);
    }
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" alt="" />
        <img src="assets/images/shape1.svg" className="shape1" alt="" />
        <img src="assets/images/shape2.svg" className="shape2" alt="" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-3">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <h2 className="text-white" style={{ fontWeight: 600 }}>Referral Reward </h2>
                </Col>
              </Row>
            </Container>
          </div>
          {/* -------------------- */}
          <div className="adv-banner mb-4">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <div className="card card-view overflow-hidden py-2">
                    <div className=" ">
                      <Row className="align-items-center justify-content-center  my-4">
                        <Col lg={10} className="text-center ">
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin1"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin2"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin3"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin4"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin5"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin6"
                          />

                          {/* <Col lg={6}> */}
                          <Row className="gy-3">
                            <Col lg={6} md={6} sm={12}>
                            <div className="mx-1">
                            {/* <Form.Label className="text-white">
                                    Referral Link
                                  </Form.Label> */}
                            <h3 className="mb-3">Referral Link</h3>
                            <Form.Group
                              controlId="formBasicEmail"
                              className="d-flex align-items-center position-relative px-3"
                            >
                              <Form.Control
                                type="text"
                                value={config.refLink + loginData?.referral_code}
                                className="mr-3 custom_field"
                                style={{ paddingRight: "100px" }}
                              />
                              
                              <CopyToClipboard
                                text={config.refLink + loginData?.referral_code}
                              >
                                <Button
                                  variant="btn btn-main2 ml-5 me-3"
                                  onClick={copieBtn}
                                >
                                  <sapn
                                    className="mr-copylink"
                                    id="token-buy-button"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Copy
                                  </sapn>
                                </Button>
                              </CopyToClipboard>
                              
                            </Form.Group>
                          </div>
                            </Col>

                            <Col lg={6} md={6} sm={12}>
                            <div className="mx-1">
                            {/* <Form.Label className="text-white">
                                    Referral Link
                                  </Form.Label> */}
                            <h3 className="mb-3">Referral Code</h3>
                            <Form.Group
                              controlId="formBasicEmail"
                              className="d-flex align-items-center position-relative px-3"
                            >
                              <Form.Control
                                type="text"
                                value={ loginData?.referral_code}
                                className="mr-3 custom_field"
                                style={{ paddingRight: "100px" }}
                              />
                              
                              <CopyToClipboard
                                text={loginData?.referral_code}
                              >
                                <Button
                                  variant="btn btn-main2 ml-5 me-3"
                                  onClick={copieBtn}
                                >
                                  <sapn
                                    className="mr-copylink"
                                    id="token-buy-button"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Copy
                                  </sapn>
                                </Button>
                              </CopyToClipboard>
                              
                            </Form.Group>
                          </div>
                            </Col>
                          </Row>
                       
                         
                          {/* </Col> */}
                        </Col>
                        <Col lg={6} className="text-center">
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin1"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin2"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin3"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin4"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin5"
                          />
                          <img
                            src="assets/images/mntcoin.png"
                            className="coin coin6"
                          />
                        </Col>
                        {/* <Col lg={6} className="mb-3 text-center">
                                <div className="referral_animation">
                                    <img src="assets/images/referral_animation.gif"  />
                                 </div>
                              </Col> */}
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}
          <div>
            <Container>
              <Row className="justify-content-end">
                <Col lg={7}>
                  {/* <div className="d-flex align-items-center">
                  <h5 className="mb-3">Referred By</h5>
                  <Form.Group
                    controlId="formBasicEmail"
                    className="position-relative w-100"
                  >
                    <Form.Control
                      type="text"
                      value={
                        userdata.referredBy == null ||
                        userdata.referredBy == undefined ||
                        userdata.referredBy == ""
                          ? "No Referral"
                          : userdata.referredBy
                      }
                      className="mr-3 custom_field"
                    />
                    {userdata.referredBy == null ||
                    userdata.referredBy == undefined ||
                    userdata.referredBy == "" ? (
                      ""
                    ) : (
                      <CopyToClipboard text={userdata.referredBy}>
                        <Button variant="btn btn-main2 ml-5" onClick={copieBtn}>
                          <sapn
                            className="mr-copylink"
                            id="token-buy-button"
                            style={{ cursor: "pointer" }}
                          >
                            Copy
                          </sapn>
                        </Button>
                      </CopyToClipboard>
                    )}
                  </Form.Group>
                </div> */}
                </Col>
              </Row>
            </Container>
          </div>
          <div className=" mb-5">
            <div className="container referral">
              <div className="mr-referral-content">
                <div className="mr-referral-block">
                  <Row>
                    <Col lg={5} md={12} className="">
                      <div className="mr-referral-carditem mr-referralref-item buyform card-view1  mb-4">
                        <div className="mr-referral-inner">
                          <div className="mr-contant-block">
                            <h4>
                              <Row>
                                <Col lg={5} sm={5} xs={5}> Direct Refers</Col>
                                <Col lg={7} sm={7} xs={7}>
                                  <div className="d-flex">
                                    <span className="seprator"> : </span>
                                    <span className="text text-break fs-6">
                                      {getreferralcount}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </h4>

                            <h4>


                              <Row>
                                <Col lg={5} sm={5} xs={5}>Total Income</Col>
                                <Col lg={7} sm={7} xs={7} className="d-flex">
                                  <span className="seprator"> : </span>
                                  <span className="text text-break fs-6">
                                    ${gettotalamountusdt}
                                  </span>
                                </Col>
                              </Row>
                            </h4>
                            <h4>


                              <Row>
                                <Col lg={5} sm={5} xs={5}>  Email</Col>
                                <Col lg={7} sm={7} xs={7} className="d-flex">
                                  <span className="seprator"> : </span>
                                  <span className="text text-white text-break">
                                    {loginData?.email}
                                  </span>
                                </Col>
                              </Row>
                            </h4>
                            <h4 >


                              <Row>
                                <Col lg={5} sm={5} xs={5}>  Referred By</Col>
                                <Col lg={7} sm={7} xs={7} className="d-flex">
                                  <span className="seprator"> : </span>
                                  <span className="text text-white text-break fs-6">
                                    <span
                                      type="text"
                                      className="mr-3   border-0 pl-0 h-auto"
                                    >
                                      {userdata.referredBy == null ||
                                        userdata.referredBy == undefined ||
                                        userdata.referredBy == ""
                                        ? "No Referral"
                                        : userdata.referredBy}
                                    </span>
                                  </span>
                                </Col>
                              </Row>
                              
                            </h4>
                            <h4>
                            <Row>
                                <Col lg={5} sm={5} xs={5}>  Rank</Col>
                                <Col lg={7} sm={7} xs={7} className="d-flex">
                                  <span className="seprator"> : </span>
                                  {getreferralcount == 0 ? (
                                    <span className="text">TRAINEE</span>
                                  ) : getreferralcount < 50 ? (
                                    <span className="text">TRAINEE</span>
                                  ) : getreferralcount < 250 ? (
                                    <span className="text">TRAINER</span>
                                  ) : getreferralcount < 750 ? (
                                    <span className="text">COACH</span>
                                  ) : getreferralcount <= 2250 ? (
                                    <span className="text">SENIOR COACH</span>
                                  ) : getreferralcount <= 6750 ? (
                                    <span className="text">CONSULTANT</span>
                                  ) : getreferralcount <= 13500 ? (
                                    <span className="text">NUTRICIAN</span>
                                  ) : getreferralcount <= 27000 ? (
                                    <span className="text">MANAGER</span>
                                  ) : getreferralcount <= 54000 ? (
                                    <span className="text">CLUB OWNER</span>
                                  ) : getreferralcount <= 54000 ? (
                                    <span className="text">GYM OWNER</span>
                                  ) : (
                                    "TRAINEEs"
                                  )}
                                </Col>
                              </Row>
                            </h4>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mr-referral-carditem mr-referralref-item buyform card-view1  mb-4">
                        <div className="mr-referral-inner">
                          <div className="mr-contant-block">
                            <h4 className="mb-0">

                  

                            </h4>
                          </div>
                        </div>
                      </div> */}
                    </Col>
                    <Col lg={7} md={12} className=" ">
                      <div className="mr-referral-carditem mr-referral-item buyform  card-view1 px-5 py-4" >
                        <div className="mr-contant-block">
                          <h4>Referral System</h4>
                          <ul className="mr-referralsteps">
                            <li className="mr-referralsteps-item mr-referral-item1">
                              <div className="mr-stepsinfo">
                                <div className="mr-inner-stepinfo">
                                  <div className="mr-iconbox">
                                    <i className="fa fa-person-plus">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="39"
                                        height="34"
                                        viewBox="0 0 39 34"
                                        fill="none"
                                      >
                                        <path
                                          d="M31.9449 33V22.3333M26.6115 27.6667H37.2782M19.5004 22.3333H12.3893C9.07596 22.3333 7.41928 22.3333 6.11246 22.8746C4.37004 23.5964 2.98569 24.9807 2.26396 26.7231C1.72266 28.03 1.72266 29.6866 1.72266 33M25.7227 1.5169C28.3287 2.57182 30.1671 5.12678 30.1671 8.11111C30.1671 11.0954 28.3287 13.6504 25.7227 14.7053M22.1671 8.11111C22.1671 12.0385 18.9833 15.2222 15.056 15.2222C11.1286 15.2222 7.94488 12.0385 7.94488 8.11111C7.94488 4.18375 11.1286 1 15.056 1C18.9833 1 22.1671 4.18375 22.1671 8.11111Z"
                                          stroke="#F4F4F4"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </i>
                                  </div>

                                  <h4>Refer your Friends</h4>
                                </div>
                                <span className="mr-step-counter">
                                  <span className="mr-step-counter-inner">
                                    1
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li className="mr-referralsteps-item mr-referral-item2">
                              <div className="mr-stepsinfo">
                                <div className="mr-inner-stepinfo">
                                  <div className="mr-iconbox mb-0">
                                    <i className="bi bi-person-bounding-box">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="46"
                                        height="45"
                                        viewBox="0 0 46 45"
                                        fill="none"
                                      >
                                        <path
                                          d="M24.3111 5.15625C24.1316 4.98567 23.9184 4.85439 23.6853 4.77081C23.4522 4.68723 23.2042 4.65317 22.9572 4.67081C22.7102 4.68846 22.4696 4.75741 22.2507 4.87328C22.0318 4.98915 21.8395 5.14938 21.6861 5.34375C21.4403 5.62306 21.2832 5.96917 21.2347 6.33803C21.1863 6.7069 21.2486 7.08185 21.4139 7.41517C21.5791 7.7485 21.8398 8.02512 22.1627 8.20986C22.4857 8.39459 22.8562 8.47908 23.2273 8.45257C23.5984 8.42607 23.9532 8.28976 24.2466 8.06101C24.54 7.83225 24.7588 7.52138 24.875 7.16795C24.9912 6.81453 24.9996 6.43452 24.8992 6.07629C24.7988 5.71806 24.594 5.39779 24.3111 5.15625Z"
                                          fill="#F4F4F4"
                                        />
                                        <path
                                          d="M30.0306 7.875C29.6709 8.28562 29.4887 8.8221 29.5238 9.36687C29.559 9.91164 29.8086 10.4203 30.2182 10.7813C30.4152 11.0256 30.6632 11.224 30.9449 11.3625C31.2265 11.5011 31.535 11.5765 31.8488 11.5835C32.1627 11.5904 32.4742 11.5288 32.7617 11.4028C33.0493 11.2769 33.3058 11.0897 33.5135 10.8544C33.7212 10.619 33.875 10.3411 33.9641 10.0401C34.0533 9.73918 34.0756 9.42237 34.0296 9.11187C33.9837 8.80136 33.8704 8.50463 33.6979 8.24242C33.5253 7.98021 33.2976 7.75883 33.0307 7.59375C32.8193 7.40629 32.5717 7.26426 32.3031 7.17645C32.0346 7.08865 31.7509 7.05695 31.4696 7.08332C31.1883 7.10969 30.9155 7.19357 30.6679 7.32975C30.4204 7.46594 30.2035 7.65152 30.0306 7.875Z"
                                          fill="#F4F4F4"
                                        />
                                        <path
                                          d="M39.7803 18C40.0933 17.6161 40.2867 17.1488 40.3367 16.656C40.3867 16.1632 40.291 15.6666 40.0614 15.2277C39.8319 14.7888 39.4787 14.4268 39.0454 14.1867C38.6122 13.9466 38.1181 13.8389 37.6242 13.8769C37.1304 13.9149 36.6585 14.0969 36.2671 14.4004C35.8757 14.704 35.582 15.1157 35.4223 15.5845C35.2626 16.0534 35.244 16.5588 35.3687 17.0382C35.4934 17.5175 35.7561 17.9497 36.1241 18.2813C36.3789 18.5108 36.6776 18.6862 37.0023 18.7967C37.3269 18.9073 37.6706 18.9508 38.0125 18.9245C38.3544 18.8981 38.6874 18.8026 38.9913 18.6437C39.2952 18.4848 39.5637 18.2658 39.7803 18Z"
                                          fill="#F4F4F4"
                                        />
                                        <path
                                          d="M39.8743 25.875C39.5585 25.5364 39.1746 25.2684 38.7478 25.0887C38.3211 24.9091 37.8611 24.8218 37.3982 24.8325C36.9353 24.8433 36.4799 24.952 36.062 25.1513C35.644 25.3507 35.273 25.6362 34.9733 25.9892C34.6736 26.3421 34.452 26.7546 34.3231 27.1993C34.1942 27.644 34.1609 28.111 34.2253 28.5695C34.2897 29.028 34.4505 29.4678 34.697 29.8597C34.9435 30.2517 35.2702 30.5871 35.6555 30.8438C36.3249 31.2896 37.1347 31.4741 37.9312 31.3622C38.7277 31.2503 39.4553 30.8497 39.9759 30.2366C40.4965 29.6235 40.7737 28.8406 40.755 28.0365C40.7362 27.2324 40.4228 26.4632 39.8743 25.875Z"
                                          fill="#F4F4F4"
                                        />
                                        <path
                                          d="M24.9674 34.7812C24.42 35.4142 24.0731 36.1957 23.971 37.0263C23.8689 37.8569 24.0161 38.6991 24.3939 39.4459C24.7718 40.1926 25.3632 40.8101 26.0929 41.2198C26.8226 41.6294 27.6577 41.8129 28.492 41.7466C29.3262 41.6804 30.1219 41.3676 30.7779 40.8479C31.4339 40.3283 31.9205 39.6252 32.1758 38.8283C32.4311 38.0313 32.4437 37.1764 32.2118 36.3723C31.9799 35.5682 31.5142 34.8512 30.8737 34.3125C30.4589 33.9473 29.975 33.6691 29.4507 33.4946C28.9263 33.32 28.3722 33.2526 27.8213 33.2963C27.2704 33.34 26.7339 33.494 26.2436 33.7491C25.7534 34.0042 25.3194 34.3552 24.9674 34.7812Z"
                                          fill="#F4F4F4"
                                        />
                                        <path
                                          d="M9.78097 32.1562C8.97608 33.1079 8.57465 34.3363 8.66221 35.5797C8.74977 36.823 9.3194 37.983 10.2497 38.8125C10.9517 39.414 11.8159 39.7946 12.7336 39.9062C13.6513 40.0179 14.5815 39.8557 15.4073 39.4401C16.233 39.0245 16.9175 38.374 17.3745 37.5704C17.8315 36.7668 18.0407 35.846 17.9757 34.9238C17.9108 34.0016 17.5746 33.1192 17.0095 32.3876C16.4444 31.656 15.6755 31.1078 14.7997 30.812C13.9238 30.5162 12.98 30.486 12.087 30.7252C11.194 30.9644 10.3917 31.4623 9.78097 32.1562Z"
                                          fill="#F4F4F4"
                                        />
                                        <path
                                          d="M7.74395 25.9163C10.5917 25.6884 12.7155 23.1952 12.4877 20.3475C12.2599 17.4998 9.76666 15.3759 6.91895 15.6038C4.07123 15.8316 1.94738 18.3248 2.1752 21.1725C2.40301 24.0202 4.89623 26.1441 7.74395 25.9163Z"
                                          fill="#F4F4F4"
                                        />
                                      </svg>
                                    </i>
                                  </div>
                                  <h4>They Purchase Plan</h4>
                                </div>
                                <span className="mr-step-counter">
                                  <span className="mr-step-counter-inner">
                                    2
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li className="mr-referralsteps-item mr-referral-item3">
                              <div className="mr-stepsinfo">
                                <div className="mr-inner-stepinfo">
                                  <div className="mr-iconbox">
                                    <i className="bi bi-piggy-bank">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="33"
                                        height="34"
                                        viewBox="0 0 33 34"
                                        fill="none"
                                      >
                                        <path
                                          d="M18.9472 11.163C18.0313 11.8159 16.9105 12.2 15.7 12.2C12.6072 12.2 10.1 9.69279 10.1 6.6C10.1 3.50721 12.6072 1 15.7 1C17.7048 1 19.4635 2.05347 20.4528 3.63703M6.9 29.9394H11.0765C11.621 29.9394 12.1622 30.0042 12.6901 30.1338L17.103 31.2062C18.0606 31.4395 19.058 31.4622 20.0256 31.2742L24.9048 30.325C26.1937 30.0739 27.3793 29.4567 28.3085 28.5528L31.7606 25.1947C32.7465 24.2374 32.7465 22.6839 31.7606 21.7249C30.873 20.8615 29.4676 20.7643 28.4634 21.4965L24.4402 24.4318C23.864 24.8529 23.1629 25.0797 22.4418 25.0797H18.5568L21.0297 25.0796C22.4235 25.0796 23.5526 23.9813 23.5526 22.6255V22.1346C23.5526 21.0088 22.7649 20.0271 21.6425 19.755L17.8258 18.8268C17.2046 18.6761 16.5685 18.6 15.929 18.6C14.3853 18.6 11.591 19.8781 11.591 19.8781L6.9 21.8398M29.3 8.2C29.3 11.2928 26.7928 13.8 23.7 13.8C20.6072 13.8 18.1 11.2928 18.1 8.2C18.1 5.10721 20.6072 2.6 23.7 2.6C26.7928 2.6 29.3 5.10721 29.3 8.2ZM0.5 21.16L0.5 30.44C0.5 31.3361 0.5 31.7841 0.674389 32.1264C0.827787 32.4274 1.07256 32.6722 1.37361 32.8256C1.71587 33 2.16392 33 3.06 33H4.34C5.23608 33 5.68413 33 6.02638 32.8256C6.32744 32.6722 6.57221 32.4274 6.72561 32.1264C6.9 31.7841 6.9 31.3361 6.9 30.44V21.16C6.9 20.2639 6.9 19.8159 6.72561 19.4736C6.57221 19.1726 6.32744 18.9278 6.02639 18.7744C5.68413 18.6 5.23608 18.6 4.34 18.6L3.06 18.6C2.16392 18.6 1.71587 18.6 1.37362 18.7744C1.07256 18.9278 0.827787 19.1726 0.67439 19.4736C0.5 19.8159 0.5 20.2639 0.5 21.16Z"
                                          stroke="#F4F4F4"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </i>
                                  </div>
                                  <h4>You start Earning</h4>
                                </div>
                                <span className="mr-step-counter">
                                  <span className="mr-step-counter-inner">
                                    3
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                          <p>
                            Welcome to the Referral System. By becoming a
                            referrer and inviting friends, you will receive 5%
                            of their purchased plan amount each time they make a
                            purchase.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          {/* ---------- */}

          <div className="latest_transaction mt-3 mb-5">
            <Container className="">
              <Row>
                <Col lg={12}>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="history"
                  >
                    <Row>
                      <Col lg={12}>
                        <Nav variant="pills" className="">
                          <Row className=" text-center mb-4" style={{ width: "100vw" }}>
                            <Col lg={3} sm={6} className="text-nowrap">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="history"
                                  className="card-view1 customnav-link"
                                  onClick={CurrentPageResetFunc}
                                >
                                  History
                                </Nav.Link>
                              </Nav.Item>
                            </Col>
                            <Col lg={3} sm={6} className="text-nowrap">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="referral_user"
                                  className="card-view1 customnav-link "
                                  onClick={CurrentPageResetFunc}
                                >
                                  Referral User List
                                </Nav.Link>
                              </Nav.Item>
                            </Col>

                          </Row>




                        </Nav>
                      </Col>
                      <Col sm={12} className="mt-3">
                        <Tab.Content>
                          <Tab.Pane eventKey="history">
                            <div className="table-responsive">
                              <table
                                width="100%"
                                className="text-center card-view"
                              >
                                {/* <colgroup>
                                    <col class="col-md-4">
                                    <col class="col-md-7">
                                    </colgroup> */}
                                <thead>
                                  <tr className="table-head">
                                    <th>S.No</th>
                                    <th>NFT Name</th>

                                    <th>Referral %</th>
                                    <th>Amount</th>
                                    <th>Joining Date</th>
                                  </tr>
                                </thead>
                                <tbody className="">
                                  {getreferral.length == 0 ? (
                                    <tr className="tr_list">
                                      {/* <td></td> */}
                                      {/* <td></td> */}
                                      <td colspan="6">No History Available</td>
                                      {/* <td></td> */}
                                      {/* <td></td> */}
                                    </tr>
                                  ) : (
                                    getreferralSliced.map((item, index) => (
                                      <tr className="tr_list">
                                        <td>
                                          {index +
                                            1 +
                                            currentPage * itemsPerPage}
                                        </td>
                                        <td>{item.name}</td>
                                        {/* <td>{item.description}</td> */}
                                        <td>
                                          {parseInt(item.referralPercent)}%
                                        </td>
                                        <td>
                                          $
                                          {parseFloat(item.usdAmount)?.toFixed(
                                            2
                                          )}
                                          ~{parseFloat(item.amount)?.toFixed(2)}
                                          &nbsp;MNT
                                        </td>
                                        <td>{item.datetime}</td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                              {getreferral.length > itemsPerPage && (
                                <div className="pagination-container">
                                  <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    previousClassName={
                                      isOnFirstPage ? "btn  disabled" : ""
                                    }
                                    nextClassName={
                                      isOnLastPage ? "btn  disabled" : ""
                                    }
                                    previousLinkClassName={
                                      isOnFirstPage ? "disabled-link" : ""
                                    }
                                    nextLinkClassName={
                                      isOnLastPage ? "disabled-link" : ""
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                      getreferral.length / itemsPerPage
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage}
                                  />
                                </div>
                              )}
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="referral_user">
                            <div className="table-responsive">
                              <table
                                width="100%"
                                className="text-center card-view"
                              >
                                <thead>
                                  <tr className="table-head">
                                    <th>S.No</th>
                                    <th>Email</th>
                                    {/* <th>Referral Count</th> */}
                                    <th>Package Count</th>
                                    <th>Purchase Amount</th>
                                    <th>BNB Address</th>
                                  </tr>
                                </thead>
                                <tbody className="">
                                  {getuserreferral.length == 0 ? (
                                    <tr className="tr_list">
                                      {/* <td></td> */}
                                      {/* <td></td> */}
                                      <td colspan="5">No List Available</td>
                                      {/* <td></td> */}
                                    </tr>
                                  ) : (
                                    getuserreferralSlice.map((item, index) => (
                                      <tr className="tr_list">
                                        <td>
                                          {index +
                                            1 +
                                            currentPage * itemsPerPage}
                                        </td>
                                        <td>{item.email}</td>
                                        {/* <td>{item.nftReferralCount}</td> */}
                                        <td>{item.packageCount}</td>
                                        <td>
                                          {parseFloat(item.purchaseAmount)}
                                        </td>
                                        <td>
                                          {" "}
                                          {item.bnb_address == 'NA' ? item.bnb_address :
                                            <span className="text-gradient">
                                              <b>
                                                {" "}
                                                <span class="text-sm text-bold text-light">
                                                  {item.bnb_address?.slice(0, 4) +
                                                    "...." +
                                                    item.bnb_address?.slice(
                                                      -4
                                                    )}{" "}
                                                </span>
                                                <CopyToClipboard
                                                  text={item.bnb_address}
                                                >
                                                  <span
                                                    title="Click to Copy"
                                                    className="mr-copylink"
                                                    id="token-buy-button"
                                                    onClick={copieBtn}
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "rgb(157 81 255)",
                                                    }}
                                                  >
                                                    <i class="fa fa-clone "></i>
                                                  </span>
                                                </CopyToClipboard>
                                              </b>
                                            </span>
                                          }
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                              {getuserreferral.length > itemsPerPage && (
                                <div className="pagination-container">
                                  <ReactPaginate
                                    previousLabel="Previous"
                                    nextLabel="Next"
                                    previousClassName={
                                      isOnFirstPage ? "btn  disabled" : ""
                                    }
                                    nextClassName={
                                      isOnLastPageuserreferral
                                        ? "btn  disabled"
                                        : ""
                                    }
                                    previousLinkClassName={
                                      isOnFirstPage ? "disabled-link" : ""
                                    }
                                    nextLinkClassName={
                                      isOnLastPageuserreferral
                                        ? "disabled-link"
                                        : ""
                                    }
                                    breakLabel={"..."}
                                    pageCount={Math.ceil(
                                      getuserreferral.length / itemsPerPage
                                    )}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    activeClassName={"active"}
                                    forcePage={currentPage}
                                  />
                                </div>
                              )}
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default Referral;
