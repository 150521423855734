import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Tab,
  Modal,
} from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import {
  DepositWithdrwalHistory,
  walletStatisticsAction,
  adressUpdateAction,
} from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import Cookies from "js-cookie";
import { UserContext } from "../context/ToggleContext";
import { FaEye } from "react-icons/fa";
import Passbook from "./Passbook/Passbook";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@material-ui/core";
import Swal from "sweetalert2";
import Web3 from "web3";

const Wallethistory = () => {
  const navigate = useNavigate();
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );
    console.log(loginData);
    
  const [wallethistorydata, setwallethistorydata] = useState({});
  const [balanceTransferHistory, setbalanceTransferHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('withdrawrequest');
  const [viewbalance, setviewbalance] = useState(false);

  const [wallethistorydatalist, setwallethistorydatalist] = useState([]);
  const [message, setmessage] = useState(
    "You have insufficient funds to transfer"
  );

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    console.log(selected, "selected");
    setCurrentPage(selected);
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  // Handle view balance click
  const handleViewBalance = () => {
    setviewbalance(!viewbalance);
  };


  const totalPages = Math.ceil(balanceTransferHistory.length / itemsPerPage);
  const totalPageswithdraw = Math.ceil(
    wallethistorydatalist.length / itemsPerPage
  );

  const isOnFirstPage = currentPage == 0;
  const isOnLastPage = currentPage == totalPages - 1;
  const isOnLastPageWithdraw = currentPage == totalPageswithdraw - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const wallethistorydatalistSlice = wallethistorydatalist.slice(
    startIndex,
    endIndex
  );

  useEffect(() => {
    wallethistory();
    wallethistorylist();
  }, []);


  const wallethistory = async () => {
    let res2 = await walletStatisticsAction();

    if (res2.success) {
      setwallethistorydata(res2.data);
    } else {
      setwallethistorydata([]);
    }
  };


  const wallethistorylist = async () => {
    let res = await DepositWithdrwalHistory();
    if (res.success) {
      setwallethistorydatalist(res.data);
    } else {
      setwallethistorydatalist([]);
    }
  };
  // --------------------------------------------------------Email verification Modal code-----------------------------------------
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpIs, setOtpIs] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [loding, setloding] = useState(false);
  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  useEffect(() => {
    let otp1 = otp[0] + otp[1] + otp[2] + otp[3];
    console.log(otp1);
    setOtpIs(otp1);
  }, [otp]);

  const handleEmailVerificationModal = async(e) => {
    // setshowModal(true);
    console.log("hello")
    Swal.fire({
      title: `Email Verification Required`,
      html: "Please verify your email address to continue. <br/> <b>Important</b>: Once your email is verified, it cannot be changed later. <br/> Thank you for your cooperation!",
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Verify!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(`${config.baseUrl}profile`);
      }
    });
  };
  // const handleMobileVerificationModal = async(e) => {
  //   Swal.fire({
  //     title: `Whatsapp Number Verification Required`,
  //     html: "Please verify your Whatsapp Number to continue. <br/> <b>Important</b>: Once your Whatsapp Number is verified, it cannot be changed later. <br/> Thank you for your cooperation!",
  //     confirmButtonColor: "#3085d6",
  //     showCancelButton: true,
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Verify!",
  //   }).then(async (result) => {
  //     if (result.isConfirmed) {
  //       navigate(`${config.baseUrl}profile`);
  //     }
  //   });
  // };
  const handleWalleteVerificationModal = async (e) => {
    // e.preventDefault();
    const result = await Swal.fire({
      text: "⚠️ Warning: This is your final wallet address. Once saved, it cannot be changed. Please ensure it's correct before continuing.",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
    if (!result.isConfirmed) {
      return;
    }
    connectMetasmaskForAddress();
  };
  
  const handleWithdrawlAction = (e) => {
    if (loginData.is_email_verify == 0) {
      handleEmailVerificationModal(e);
      return
    }
    // if (loginData.is_phone_verify == false) {
    //   handleMobileVerificationModal(e);
    //   return
    // }
    if (loginData.bnb_address == null) {
      handleWalleteVerificationModal(e);
    }
    navigate(`${config.baseUrl}withdraw`);
    
  }

  const connectMetasmaskForAddress = async (e) => {

    // e.preventDefault()

    try {
      if (window.ethereum) {

        var web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        console.log(accounts, "///", window.ethereum);
        let chainId = await web3.currentProvider.chainId;
        if (!chainId) {

          chainId = web3.currentProvider.getChainId();
        }
        if (chainId != config.chainId && web3.utils.toHex(parseInt(chainId)) != config.chainId) {
          toast.error(`Please select BNB smartchain!!`);

          return false;
        } else {
          console.log(accounts[0]);
          if (accounts[0]) {
            let publicAddress = accounts[0];
            const { signature } = await new Promise((resolve, reject) =>
              web3.eth.personal.sign(
                web3.utils.fromUtf8(`Login Mrmint`),
                publicAddress,
                (err, signature) => {
                  if (err) return reject(err);
                  return resolve({ publicAddress, signature });
                }
              )
            );
            let res = await adressUpdateAction({
              bnb_address: accounts[0],
            });
            console.log("test3");
            if (res.success) {
              loginData.bnb_address = accounts[0];
              Cookies.set("loginSuccessStepMrMint", JSON.stringify(loginData));
              window.location.href = `${config.baseUrl}wallethistory`;
              toast.success(res.msg);
            } else {
              toast.error(res.msg);
            }
          }
        }
      } else {

        toast.error("Please use Dapp browser!!");
      }
    } catch (error) {
      console.log('error', error);


      switch (error.code) {
        case 4001:
          toast.error("User Rejected");
          break;
        case -32002:
          toast.error(
            "Request Already Processing, Please check your Meta Mask wallet"
          );
          break;
        case "ERR_NETWORK":
          toast.error("We're experiencing technical difficulties.");
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" alt="" />
        <img src="assets/images/shape1.svg" className="shape1" alt="" />
        <img src="assets/images/shape2.svg" className="shape2" alt="" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-4">
            <Container className="">
              <Row className="align-items-center">
                <Col lg={9}>
                  <h2 className="text-white" style={{ fontWeight: 600 }}>Wallet Overview</h2>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}
          <div className="mb-5 ">
            <Container className="">
              <Row>
                <Col lg={5} md={12}  className="mb-3">
                  <div className="wallet_card">
                    <div className="card-view3 p-4 ">
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="text-light-grey mb-3">
                              Stepmint Wallet
                            </h4></div>
                          <div className="mb-3">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={viewbalance}
                                  onChange={handleViewBalance}
                                />
                              }
                              label={<p className="mb-0" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }} style={{ fontSize: 10, width: 30 }}>{viewbalance ? "Hide" : "Show"}</p>}
                              style={{ color: "white" }}
                            />
                          </div>
                        </div>

                        <>
                          <span className="">
                            <img
                              src="assets/images/mntcoin.png"
                              width="20px"
                            />
                            &nbsp;{viewbalance ? wallethistorydata.stepBalance > 0 ? wallethistorydata.stepBalance : 0 : '********'} MNT
                          </span>

                        </>
                        <div className="w-btn-list justify-content-between gap-2 flex-wrap mt-4  h-100">
                          <div>
                            
                              <button
                                className="w-btn card-view w-100"
                                onClick={(e)=>handleWithdrawlAction(e)}
                              >
                                <div className="">
                                  <span className="circle-icon">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="13"
                                      viewBox="0 0 23 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M21.3818 5.5H1.38184M1.38184 9.5H4.92851C5.4687 9.5 5.7388 9.5 5.99997 9.54655C6.23179 9.58788 6.45794 9.65635 6.67374 9.75056C6.91688 9.85669 7.14161 10.0065 7.59107 10.3062L8.1726 10.6938C8.62206 10.9935 8.8468 11.1433 9.08993 11.2494C9.30574 11.3437 9.53188 11.4121 9.7637 11.4534C10.0249 11.5 10.295 11.5 10.8352 11.5H11.9285C12.4687 11.5 12.7388 11.5 13 11.4534C13.2318 11.4121 13.4579 11.3437 13.6737 11.2494C13.9169 11.1433 14.1416 10.9935 14.5911 10.6938L15.1726 10.3062C15.6221 10.0065 15.8468 9.85669 16.0899 9.75056C16.3057 9.65635 16.5319 9.58788 16.7637 9.54655C17.0249 9.5 17.295 9.5 17.8352 9.5H21.3818M1.38184 4.2L1.38184 13.8C1.38184 14.9201 1.38184 15.4802 1.59982 15.908C1.79157 16.2843 2.09753 16.5903 2.47385 16.782C2.90168 17 3.46173 17 4.58184 17L18.1818 17C19.3019 17 19.862 17 20.2898 16.782C20.6661 16.5903 20.9721 16.2843 21.1639 15.908C21.3818 15.4802 21.3818 14.9201 21.3818 13.8V4.2C21.3818 3.0799 21.3818 2.51984 21.1639 2.09202C20.9721 1.7157 20.6661 1.40974 20.2898 1.21799C19.862 1 19.3019 1 18.1818 1L4.58184 1C3.46173 1 2.90168 1 2.47386 1.21799C2.09753 1.40973 1.79157 1.71569 1.59982 2.09202C1.38184 2.51984 1.38184 3.07989 1.38184 4.2Z"
                                        stroke="#F4F4F4"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                  &nbsp;&nbsp;<small>Withdraw</small>
                                </div>
                              </button>
                            
                          </div>
                          <div>
                          {/* {
                          loginData.bnb_address == null ?
                       
                          <button
                          className="w-btn card-view w-100"
                          onClick={handleWalleteVerificationModal}
                        >
                          <div className="">
                          <span className="circle-icon">
                                    <img src="assets/images/metamaskicon.png
                                    " alt=""  style={{width:22 , height:22}}/>
                                  </span>
                           
                          &nbsp;<small> Connect Wallet</small>
                          </div>
                        </button>
                        :

                        ""

                      } */}

                          
                          
                        </div>
                        </div>
                      </div>

                      <div className="chart_icon" style={{ zIndex: -1 }}>
                        <img src="assets/images/chart_wave.png" />
                      </div>
                    </div>
                  </div>
                </Col>

                {wallethistorydata.mainBalance <= 0 ||
                  !wallethistorydata.mainBalance ? (
                  <Col lg={4} className="mb-3"></Col>
                ) : (
                  <Col lg={4} className="mb-3">
                    <div className="wallet_card">
                      <div className="card-view3 p-3">
                        <div className="">
                          <h4 className="text-light-grey mb-3">Main Wallet</h4>
                          <div className="fs-5">
                            <img
                              src="assets/images/mntcoin.png"
                              width="25px"
                              alt=""
                            />
                            &nbsp; {wallethistorydata.mainBalance} MNT
                          </div>
                        </div>

                        <div className="chart_icon">
                          <img src="assets/images/chart_wave.png" />
                        </div>
                      </div>
                    </div>
                  </Col>
                )}


         
              </Row>
            </Container>
          </div>
          {/* ---------- */}

          <div className="transaction mb-5">
            <Container>
              <h2 className="text-white mb-4" style={{ fontWeight: 600 }}>Statistics</h2>
              <div className="transaction_detail">
                <Row>
                  <Col lg={4} className="mb-4 d-flex r">
                    <div className="listbox flex-fill ">
                      <Row className="align-items-center ">
                        <Col lg={8} xs={6} className="align-items-center text-nowrap">
                          <p className="mb-0">Referral Rewards</p>
                        </Col>
                        <Col lg={4} xs={6} className="text-right">
                          <p className="text-white mb-0">
                            {wallethistorydata.referralAmount}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={4} className="mb-4 d-flex">
                    <div className="listbox flex-fill">
                      <Row className="align-items-center">
                        <Col lg={8} xs={6}>
                          <p className="mb-0">Community Rewards</p>
                        </Col>
                        <Col lg={4} xs={6} className="text-right">
                          <p className="text-white mb-0" style={{ wordWrap: "break-word" }}>
                            {wallethistorydata.matchingAmount}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={4} className="mb-4 d-flex">
                    <div className="listbox flex-fill ">
                      <Row className="align-items-center ">
                        <Col lg={6} xs={7}>
                          <p className="mb-0 text-nowrap">Step Rewards</p>
                        </Col>
                        <Col lg={6} xs={5} className="text-right">
                          <p className="text-white mb-0">
                            {wallethistorydata.stepReward}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} className="mb-4 d-flex r">
                    <div className="listbox flex-fill ">
                      <Row className="align-items-center ">
                        <Col lg={8} xs={6} className="align-items-center text-nowrap">
                          <p className="mb-0">Walker Loyalty Rewards</p>
                        </Col>
                        <Col lg={4} xs={6} className="text-right">
                          <p className="text-white mb-0">
                            {wallethistorydata.loyalty_earning}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  {/* <Col lg={4} className="mb-4 d-flex">
                    <div className="listbox flex-fill">
                      <Row className="align-items-center">
                        <Col lg={8} xs={6}>
                          <p className="mb-0">Challenger's Ground Rewards</p>
                        </Col>
                        <Col lg={4} xs={6} className="text-right">
                          <p className="text-white mb-0" style={{ wordWrap: "break-word" }}>
                            ${wallethistorydata.matchingAmount}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col> */}
                  <Col lg={4} className="mb-4 d-flex r">
                    <div className="listbox flex-fill ">
                      <Row className="align-items-center ">
                        <Col lg={8} xs={6} className="align-items-center text-nowrap">
                          <p className="mb-0">Total Withdraw</p>
                        </Col>
                        <Col lg={4} xs={6} className="text-right">
                          <p className="text-white mb-0">
                            {wallethistorydata.total_withdraw}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                </Row>

              </div>
            </Container>
          </div>
          {/* ---------- */}
          <div className="latest_transaction mb-5">
            <Container>
              {/* <h3 className="text-white mb-4">Transactions</h3> */}
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="withdrawrequest"
                onSelect={(key) => setActiveTab(key)}
              >
                <Nav variant="pills" className=" text-center ">
                  <Row className="w-100 text-center mb-4">
                    <Col lg={3} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          onClick={CurrentPageResetFunc}
                          eventKey="withdrawrequest"
                          className="card-view1 customnav-link px-0"
                        >
                          Transactions
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    {/* <Col lg={3} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          onClick={CurrentPageResetFunc}
                          eventKey="passbook"
                          className="card-view1 customnav-link px-0 text-nowrap "
                        >
                          Transactions Summary
                          </Nav.Link>
                      </Nav.Item>
                    </Col>  */}
                  </Row>


                </Nav>
                <Tab.Content >
                  <Tab.Pane eventKey="withdrawrequest" >
                    <div className="table-responsive">
                      <table width="100%">
                        <thead>
                          <tr className="table-head">
                            <th>S.No</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Requested Date</th>
                            <th>Approve Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {wallethistorydatalist.length == 0 ? (
                            <tr className="tr_list">
                              <td colspan="6">No Data Available</td>
                            </tr>
                          ) : (
                            wallethistorydatalistSlice.map(
                              (item, index) => (
                                <tr class="tr_list">
                                  <td>
                                    {index + 1 + currentPage * itemsPerPage}
                                  </td>{" "}
                                  <td>{item.usd_amount}&nbsp;MNT</td>
                                  <td
                                    style={{
                                      color: `${item.status == "Rejected"
                                        ? "Red"
                                        : item.status == "Approved"
                                          ? "Green"
                                          : "#D3D3D3"
                                        }`,
                                    }}
                                  >
                                    {item.status}
                                  </td>
                                  <td>
                                    {item.status == "Deposited"
                                      ? item.time?.slice(0, 10)
                                      : item?.requestDate?.slice(0, 10)}
                                  </td>
                                  <td>
                                    {item.status == "Rejected"
                                      ? ""
                                      : item?.approvedDate?.slice(0, 10)}
                                  </td>
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                      {wallethistorydatalist.length > itemsPerPage && (
                        <div className="pagination-container">
                          <ReactPaginate
                            previousLabel="Previous"
                            nextLabel="Next"
                            previousClassName={
                              isOnFirstPage ? "btn  disabled" : ""
                            }
                            nextClassName={
                              isOnLastPageWithdraw ? "btn  disabled" : ""
                            }
                            previousLinkClassName={
                              isOnFirstPage ? "disabled-link" : ""
                            }
                            nextLinkClassName={
                              isOnLastPageWithdraw ? "disabled-link" : ""
                            }
                            breakLabel={"..."}
                            pageCount={Math.ceil(
                              wallethistorydatalist.length / itemsPerPage
                            )}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="passbook">
                    {activeTab === 'passbook' && <Passbook />}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
export default Wallethistory;
